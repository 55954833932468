// TBD - Only placeholder until we start working on this module, paths might change
import { RouteNames } from '@/types/routes';
import type { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
  {
    path: '',
    name: RouteNames.EVALUATE,
    meta: {
      requiresBeta: true,
    },
    children: [
      {
        path: 'datasets',
        redirect: { name: RouteNames.DATASETS },
        children: [
          {
            name: RouteNames.DATASETS,
            path: '',
            components: {
              default: () => import('./views/ListDatasets.vue'),
            },
          },
          {
            path: ':datasetId',
            name: RouteNames.DATASET,
            components: {
              default: () => import('./views/ViewDataset.vue'),
            },
          },
        ],
      },
      {
        path: 'benchmarks',
        name: RouteNames.BENCHMARKS,
        components: {
          default: () => import('./views/ListBenchmarks.vue'),
        },
      },
    ],
  },
];
export default routes;
